import { ADTSettings } from 'angular-datatables/src/models/settings';

/**
 * Set default renderer to 'text' to mitigate XSS attacks.
 *
 * https://datatables.net/manual/security#Prevention
 */
export const escapeTextByDefault: DataTables.ColumnDefsSettings[] = [
  {
    targets: '_all',
    render: $.fn.dataTable.render.text(),
  },
] as const;

/**
 * For datatable instances that are rendered using *inline* templates (in an
 * `ngFor` loop), the text is sanitized by Angular and should not be escaped.
 * Only use this if data is NOT provided via `dtOptions.data`.
 *
 * ```
 * readonly dtOptions: ADTSettings = {
 *   columnDefs: allowUnsafeHtmlByDefault,
 * } as const;
 * ```
 *
 * For other datatable types, always use {@link escapeTextByDefault}.
 */
const allowUnsafeHtmlByDefault: DataTables.ColumnDefsSettings[] = [] as const;

// Explicitly exclude `data` to make it harder to accidentally use dynamic rendering.
type InlineTemplateDTSettings = Omit<ADTSettings, 'data'>;

/**
 * For datatable instances that are rendered using *inline* templates (in an
 * `ngFor` loop), the text is sanitized by Angular and should not be escaped.
 *
 * For other datatable types, always use {@link escapeTextByDefault}.
 *
 * @see {@link allowUnsafeHtmlByDefault}
 */
export const dtOptionsForUseWithInlineTemplates: InlineTemplateDTSettings = {
  columnDefs: allowUnsafeHtmlByDefault,
} as const;
